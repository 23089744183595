import React, { useEffect, useRef, useState } from "react";
import AboutMe from "../../components/AboutMe";
import Contact from "../../components/Contact";
import Experience from "../../components/Experience";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Projects from "../../components/Projects";
import Skills from "../../components/Skills";

const Home = () => {
  const [showButton, setShowButton] = useState(false);

  const aboutMeRef = useRef<HTMLDivElement>(null);
  const skillsRef = useRef<HTMLDivElement>(null);
  const experienceRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToComponent = (ref: React.RefObject<HTMLDivElement>) => {
    return () => ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header
        scrollToContact={scrollToComponent(contactRef)}
        scrollToAboutMe={scrollToComponent(aboutMeRef)}
        scrollToExperience={scrollToComponent(experienceRef)}
        scrollToProjects={scrollToComponent(projectsRef)}
        scrollToSkills={scrollToComponent(skillsRef)}
      />
      <AboutMe ref={aboutMeRef} />
      <Skills ref={skillsRef} />
      <Experience ref={experienceRef} />
      <Projects ref={projectsRef} />
      <Contact ref={contactRef} />
      <Footer />
      <div className="fixed bottom-3 right-3">
        {showButton && (
          <button
            type="button"
            onClick={scrollToTop}
            className="bg-black inline-flex items-center rounded-full p-3 text-white shadow-sm transition-opacity"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fill-rule="evenodd"
                d="M11.47 7.72a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 01-1.06-1.06l7.5-7.5z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  );
};

export default Home;
