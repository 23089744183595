import React from "react";

type Props = {
  src: string;
  name: string;
};

const SkillItem = ({ src, name }: Props) => {
  return (
    <>
      <div className="w-11 h-11 rounded-lg mx-3 my-3 grid grid-cols-1 group hover:scale-110">
        <div className="flex justify-center">
          <img className="inline-block h-11 w-11 rounded-md" src={src} alt="" />
        </div>
        <div className={"hidden justify-center group-hover:flex mt-0"}>
          <p className="text-[13px] text-gray-700 font-medium m-0 mt-1 whitespace-nowrap">
            {name}
          </p>
        </div>
      </div>
    </>
  );
};

export default SkillItem;
