import React from "react";
import emailjs from "@emailjs/browser";

const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const public_key = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

const Contact = React.forwardRef<HTMLDivElement>((props, ref) => {
  const form = React.useRef<HTMLFormElement>(null);

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(service_id!, template_id!, form.current!, public_key!)
      .then(
        (result: any) => {
          console.log(result.text);
          Array.from(e.target).forEach((e: any) => (e.value = ""));
        },
        (error: any) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div
        ref={ref}
        className="mx-auto max-w-7xl px-4 pt-7 sm:px-6 lg:px-8 lg:mt-20"
      >
        <div className="relative mb-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="bg-white pr-3 text-5xl font-bold text-gray-900">
              Contact
            </span>
          </div>
        </div>

        <div className="overflow-hidden bg-white lg:pb-24">
          <div className="relative max-w-2xl">
            <div>
              <p className="text-2xl text-gray-500">
                Want to get in touch? I'd love to hear from you! Fill this form
                and send it in. I'll get back to you as soon as possible.
              </p>
            </div>
            <div className="mt-12">
              <form
                ref={form}
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                onSubmit={sendEmail}
              >
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-lg font-medium text-gray-700"
                  >
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-gray-300 py-3 px-4 mx-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-lg font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-gray-300 py-3 px-4 mx-1 shadow-sm focus:border-indigo-500"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-lg font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="block w-full rounded-md border-gray-300 py-3 px-4 mx-1  shadow-sm focus:border-black focus:ring-black"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <button
                    type="submit"
                    className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-black px-6 py-3 text-xl font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Let's talk
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Contact;
