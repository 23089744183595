import React from "react";
import "./App.css";
import AppRouter from "./components/AppRouter";
// import Error from "./pages/Error";

function App() {
  return <AppRouter />;
  // return <Error />;
}

export default App;
