import React from "react";
import SkillItem from "../SkillItem";

type iconProps = {
  name: string;
  src: string;
  link?: string;
};

type Props = {
  header: string;
  icons: iconProps[];
};

const TechStack = ({ header, icons }: Props) => {
  return (
    <>
      <div>
        <p className="text-2xl font-semibold text-gray-600 mt-8">{header}</p>
        <div className="flex">
          {icons.map((icon, index) =>
            icon.link ? (
              <a href={icon.link || ""}>
                <SkillItem key={index} name={icon.name} src={icon.src} />
              </a>
            ) : (
              <SkillItem key={index} name={icon.name} src={icon.src} />
            )
          )}
        </div>
      </div>
    </>
  );
};

export type { iconProps };
export default TechStack;
