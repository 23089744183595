import React from "react";
import github from "../../assets/icons/github-icon.svg";
import linkedin from "../../assets/icons/linkedin-icon.svg";
import mail from "../../assets/icons/mail-icon.svg";

const Footer = () => {
  const social = [
    {
      name: "Mail",
      href: "mailto:muthukrishnansantosh@gmail.com",
      icon: mail,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/in/santosh-muthukrishnan/",
      icon: linkedin,
    },
    {
      name: "GitHub",
      href: "https://github.com/santosh3007",
      icon: github,
    },
  ];

  return (
    <>
      <footer className="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <img src={item.icon} className="h-10 w-10" alt="" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-xl text-gray-400 font-medium md:order-1 md:mt-0">
              Santosh Muthukrishnan
            </p>{" "}
            <p className="mt-8 text-xl font-medium text-black md:order-1 md:mt-0">
              Thanks for Visiting!
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
