import React from "react";
import SkillItem from "../SkillItem";
import js from "../../assets/icons/js.svg";
import ts from "../../assets/icons/typescript-icon.svg";
import ec2 from "../../assets/icons/aws-ec2-icon.svg";
import elastic_beanstalk from "../../assets/icons/aws-elastic-beanstalk-icon.svg";
import s3 from "../../assets/icons/aws-s3-icon.svg";
import c from "../../assets/icons/c-icon.svg";
import docker from "../../assets/icons/docker-icon.svg";
import git from "../../assets/icons/git-scm-icon.svg";
import html from "../../assets/icons/w3_html5-icon.svg";
import tailwindcss from "../../assets/icons/tailwindcss-icon.svg";
import react from "../../assets/icons/reactjs-icon.svg";
import redux from "../../assets/icons/redux-icon.svg";
import rails from "../../assets/icons/rails.svg";
import sass from "../../assets/icons/sass-lang-icon.svg";
import java from "../../assets/icons/java-icon.svg";
import python from "../../assets/icons/python-icon.svg";
import ruby from "../../assets/icons/ruby-icon.svg";
import nestjs from "../../assets/icons/nestjs-icon.svg";
import postgresql from "../../assets/icons/postgresql.svg";
import ionic from "../../assets/icons/ionic-icon.svg";
import materialui from "../../assets/icons/material-ui-icon.svg";
import figma from "../../assets/icons/figma-icon.svg";
import awsglue from "../../assets/icons/aws-glue-icon.svg";
import airflow from "../../assets/icons/airflow-icon.svg";
import spark from "../../assets/icons/spark-icon.png";
import databricks from "../../assets/icons/databricks-icon.png";
import express from "../../assets/icons/express-icon.svg";
import elixir from "../../assets/icons/elixir-lang-icon.svg";
import lakeformation from "../../assets/icons/lakeformation-icon.svg";
import scala from "../../assets/icons/scala-icon.svg";

const Skills = React.forwardRef<HTMLDivElement>((props, ref) => {
  const languages_experienced = [
    { name: "JavaScript", href: js },
    { name: "TypeScript", href: ts },
    { name: "Java", href: java },
    { name: "HTML", href: html },
    { name: "Ruby", href: ruby },
    { name: "Elixir", href: elixir },
    { name: "Python", href: python },
  ];

  const languages_familiar = [
    { name: "C++", href: c },
    { name: "Scala", href: scala },
  ];

  const technologies_experienced = [
    { name: "React", href: react },
    { name: "Redux", href: redux },
    { name: "Rails", href: rails },
    { name: "PostgreSQL", href: postgresql },
    { name: "Ionic", href: ionic },
    { name: "Material UI", href: materialui },
    { name: "Docker", href: docker },
    { name: "AWS EBS", href: elastic_beanstalk },
    { name: "Git", href: git },
    { name: "TailwindCSS", href: tailwindcss },
    { name: "AWS EC2", href: ec2 },
    { name: "AWS S3", href: s3 },
    { name: "NestJS", href: nestjs },
  ];

  const technologies_familiar = [
    { name: "AWS EC2", href: ec2 },
    { name: "Sass", href: sass },
    { name: "Figma", href: figma },
    { name: "AWS Glue", href: awsglue },
    { name: "AWS Lake Formation", href: lakeformation },
    { name: "Airflow", href: airflow },
    { name: "Spark", href: spark },
    { name: "Databricks", href: databricks },
    { name: "Express", href: express },
  ];

  return (
    <>
      <div
        ref={ref}
        className="mx-auto max-w-7xl px-4 pt-7 sm:px-6 lg:px-8 lg:mt-20"
      >
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="bg-white pr-3 text-5xl font-bold text-gray-900">
              Skills
            </span>
          </div>
        </div>
        <div className="ml-3 mt-10">
          <p className="text-2xl font-bold text-gray-600 mt-3">Programming</p>
          <div>
            <p className="ml-3 text-xl font-bold text-gray-400 mt-2">
              Experienced
            </p>
            <div className="flex">
              {languages_experienced.map((language, index) => (
                <SkillItem
                  key={index}
                  src={language.href}
                  name={language.name}
                />
              ))}
            </div>
          </div>
          <div>
            <p className="ml-3 text-xl font-bold text-gray-400 mt-2">
              Familiar
            </p>
            <div className="flex">
              {languages_familiar.map((language, index) => (
                <SkillItem
                  key={index}
                  src={language.href}
                  name={language.name}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="ml-3 mt-10">
          <p className="text-2xl font-bold text-gray-600 mt-3">Technologies</p>
          <div>
            <p className="ml-3 text-xl font-bold text-gray-400 mt-2">
              Experienced
            </p>
            <div className="flex">
              {technologies_experienced.map((language, index) => (
                <SkillItem
                  key={index}
                  src={language.href}
                  name={language.name}
                />
              ))}
            </div>
          </div>
          <div>
            <p className="ml-3 text-xl font-bold text-gray-400 mt-2">
              Familiar
            </p>
            <div className="flex">
              {technologies_familiar.map((language, index) => (
                <SkillItem
                  key={index}
                  src={language.href}
                  name={language.name}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Skills;
