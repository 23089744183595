import React from "react";

type experience = {
  company: string;
  position: string;
  start_date: string;
  end_date: string;
  description: string[];
  link?: string;
  location?: string;
};

const Experience = React.forwardRef<HTMLDivElement>((props, ref) => {
  const experiences: experience[] = [
    {
      company: "Asana",
      position: "Software Engineering Intern (Data Infrastructure)",
      start_date: "May 2023",
      end_date: "Present",
      description: [
        "As part of the Core Data Infrastructure team, taking part in cross-functional efforts in maintaining critical data infrastructure and orchestration tools that Data Engineers and Scientists rely on for analysis.",
        // "Assisting with developing Airflow operators to query data from data lake in pipelines with Databricks, to be used across more than 20 engineering and data science teams.",
        "Currently interning – Exciting updates coming soon!",
      ],
      link: "https://asana.com/",
      location: "San Francisco, United States",
    },
    {
      company: "Source Academy",
      position: "Software Engineer",
      start_date: "Jan 2023",
      end_date: "Present",
      description: [
        "Designed and implemented a notifications framework using Bamboo, Elixir and Phoenix ensuring that extendibility across various notification mediums, helping over 1,000 students every semester across the world.",
        "Revamping background job processing using Oban and Elixir, ensuring fault-tolerant execution, and extensive tracking and monitoring of jobs.",
      ],
      link: "https://source-academy.github.io/",
      location: "Singapore",
    },
    {
      company: "Google Developer Student Clubs",
      position: "Software Engineer",
      start_date: "Aug 2022",
      end_date: "Present",
      description: [
        "GDSC is a club that develops technology for social good mainly supporting non-profit organisations.",
        "Creating an online learning platform for Dyslexic Association of Singapore that is engaging and customised to dyslexic students’ learning needs by using a multi-sensory approach.",
        "Developing a progressive web application that allows teachers to create and share learning resources customised to each student.",
      ],
      link: "https://dsc.comp.nus.edu.sg/",
      location: "Singapore",
    },
    {
      company: "NUS",
      position: "Undergraduate Teaching Assistant",
      start_date: "Aug 2022",
      end_date: "May 2023",
      description: [
        "CS2040S - Data Structures and Algorithms",
        "CS1101S - Programming Methodology",
        "Conducting weekly tutorial sessions for Computer Science freshmen teaching them fundamental concepts of programming and computational problem solving.",
        "Grading programming assignments for each student and providing feedback on code quality and correctness.",
      ],
      link: "https://www.comp.nus.edu.sg/",
      location: "Singapore",
    },
    {
      company: "Computing for Voluntary Welfare Organisations",
      position: "Software Engineer Intern",
      start_date: "May 2022",
      end_date: "Aug 2022",
      description: [
        "Computing for Voluntary Welfare Organisations (CVWO) is an organisation that builds IT systems to help VWOs serve the community more effectively.",
        "Led a team of four developers to conceptualize and develop a Progressive Web Application for senior citizens to sign up for activities at Active Ageing Centres, reducing the workload of volunteers using React, Ionic, Ruby on Rails.",
        "Implemented enhanced authentication with SingPass to allow seniors to use our application securely and in a hassle-free way.",
        "Spearheaded backend upgrade from Rails 5.2.0 to 6.1.6 and Ruby 2.5.1 to 2.7.6, making sure there were zero regressions along the way and improving performance and security of Lions Befrienders’ management system, which is currently being used by over 2500 staff, serving over 20,000 seniors.",
      ],
      link: "https://www.comp.nus.edu.sg/~VWO/",
      location: "Singapore",
    },
  ];

  return (
    <>
      <div
        ref={ref}
        className="mx-auto max-w-7xl px-4 pt-7 sm:px-6 lg:px-8 lg:mt-20"
      >
        <div className="relative mb-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="bg-white pr-3 text-5xl font-bold text-gray-900">
              Experience
            </span>
          </div>
        </div>
        <div className="flow-root">
          <ul className="-mb-8">
            {experiences.map((experience, index) => (
              <li key={index} className="mt-10">
                <div className="relative pb-4">
                  {index !== experiences.length ? (
                    <span
                      className={
                        "absolute top-10 left-6 -ml-px " +
                        (index !== experiences.length - 1
                          ? "h-full"
                          : "h-[95%]") +
                        " w-0.5 bg-white"
                      }
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative w-full align-top justify-start">
                    <span className="m-3 pl-1 flex items-start justify-center ring-8 ring-white bg-white">
                      <p className="text-3xl font-bold text-gray-900">
                        {experience.position}{" "}
                        <a
                          href={experience.link || "#"}
                          className="text-2xl font-semibold text-gray-500 hover:text-gray-900 hover:underline"
                        >
                          @ {experience.company}
                        </a>
                      </p>
                      {
                        // add location vertically to the bottom of start and end date
                      }
                      <div className=" md:text-right font-medium text-xl flex-grow float-right">
                        <p className=" mt-1">
                          {experience.start_date} - {experience.end_date}
                        </p>
                        <p className="float-right text-gray-500">
                          {experience.location}
                        </p>
                      </div>
                    </span>
                  </div>
                  <div className="ml-14">
                    <ul className="pt-4">
                      {experience.description.map((desc, index) => (
                        <li className="list-disc mb-2" key={index}>
                          <p className="font-medium text-xl">{desc}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
});

export default Experience;
