import React from "react";
import wave from "../../assets/images/wave.png";

const AboutMe = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <>
      <div ref={ref} className="mx-auto max-w-7xl mt-60 px-4 sm:px-6 lg:px-8">
        <img src={wave} alt="wave" className="w-16 mt-20 mb-10" />
        <p className="text-6xl font-bold">
          Hi there! I'm Santosh, and I love working on technologies that make a
          difference. {/* Montserrat maybe*/}
        </p>
        <p className="text-2xl font-semibold pt-2 mt-10">
          I'm a Penultimate year undergraduate studying at National University
          of Singapore.
        </p>
        <p className="text-2xl font-semibold pt-2 mt-4">
          Currently a Software Engineer Intern @{" "}
          <a
            href="
          https://www.asana.com/
          "
            className=" font-semibold text-gray-700 hover:text-gray-900 hover:underline"
          >
            Asana
          </a>
        </p>
      </div>
    </>
  );
});

export default AboutMe;
