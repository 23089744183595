import React, { useState } from "react";
import Resume from "../../assets/resume/Resume.pdf";

type Props = {
  scrollToContact: () => void;
  scrollToAboutMe: () => void;
  scrollToSkills: () => void;
  scrollToExperience: () => void;
  scrollToProjects: () => void;
};

const Header = ({
  scrollToExperience,
  scrollToAboutMe,
  scrollToContact,
  scrollToProjects,
  scrollToSkills,
}: Props) => {
  const navigation = [
    { name: "about me", scroll: scrollToAboutMe },
    { name: "skills", scroll: scrollToSkills },
    { name: "experience", scroll: scrollToExperience },
    { name: "projects", scroll: scrollToProjects },
    { name: "contact", scroll: scrollToContact },
  ];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-end justify-between py-6">
          <div className="flex items-center">
            <a href="/">
              <span className="sr-only">Santosh Muthukrishnan</span>
              {/* <img
                className="h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=white"
                alt=""
              /> */}
              <p className="text-5xl font-bold text-black">Santosh</p>
            </a>
          </div>
          <div className="ml-10 space-x-4">
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <button
                  key={link.name}
                  onClick={link.scroll}
                  className="text-2xl font-medium text-black border-b-2 border-transparent hover:border-black transition ease-in duration-250" // TODO: transition on hover
                >
                  {link.name}
                </button>
              ))}
              <a
                href={Resume}
                target="_blank"
                rel="noopener noreferrer"
                className="text-2xl font-medium text-black border-b-2 border-transparent hover:border-black transition ease-in duration-250"
              >
                resume
              </a>
            </div>
          </div>
          <div className="lg:hidden" id="mobile-menu" onClick={toggleMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
        </div>

        <div className="flex flex-col flex-wrap px-3 py-4 lg:hidden">
          {isMenuOpen && ( // https://tailwindui.com/components/marketing/sections/heroes#component-b9bcab4538776a17fff93d18f82a8272 (TODO: for popover effect)
            <>
              {navigation.map((link) => (
                <button
                  key={link.name}
                  onClick={link.scroll}
                  className="text-base font-medium text-black text-left border-b-2 border-transparent hover:border-black transition ease-in duration-250" // TODO: Fix underline length on hover
                >
                  {link.name}
                </button>
              ))}
              <a
                href={Resume}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base font-medium text-black border-b-2 border-transparent hover:border-black transition ease-in duration-250"
              >
                resume
              </a>
            </>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
