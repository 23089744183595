import React from "react";
import react from "../../assets/icons/reactjs-icon.svg";
import redux from "../../assets/icons/redux-icon.svg";
import rails from "../../assets/icons/rails.svg";
import tailwindcss from "../../assets/icons/tailwindcss-icon.svg";
import ruby from "../../assets/icons/ruby-icon.svg";
import docker from "../../assets/icons/docker-icon.svg";
import postgresql from "../../assets/icons/postgresql.svg";
import TechStack from "../TechStack";
import aws_ec2 from "../../assets/icons/aws-ec2-icon.svg";
import aws_ebs from "../../assets/icons/aws-elastic-beanstalk-icon.svg";
import aws_s3 from "../../assets/icons/aws-s3-icon.svg";
import { iconProps } from "../TechStack/TechStack";
import recyclops_image from "../../assets/images/recyclops_demo.png";
import getitdone from "../../assets/images/GetItDone.png";
import flask from "../../assets/icons/flask-icon.svg";
import pytorch from "../../assets/icons/pytorch-icon.svg";
import ionic from "../../assets/icons/ionic-icon.svg";
import materialui from "../../assets/icons/material-ui-icon.svg";
import figma from "../../assets/icons/figma-icon.svg";
import ecoquest from "../../assets/images/ecoquest.png";
import visit from "../../assets/icons/visit.svg";
import github from "../../assets/icons/github-icon.svg";
import youtube from "../../assets/icons/youtube-icon.svg";
import python from "../../assets/icons/python-icon.svg";
import devpost from "../../assets/icons/devpost-icon.svg";

type Project = {
  name: string;
  description: string;
  techStack: iconProps[];
  link: string;
  img_src: string;
  links: iconProps[];
};

const Projects = React.forwardRef<HTMLDivElement>((props, ref) => {
  const projects: Project[] = [
    {
      name: "EcoQuest",
      description:
        "EcoQuest is a mobile app designed to engage users in environmental conservation for the Google Solutions Hackathon 2023. It uses machine learning for object detection to validate user submissions, allowing users to earn points for completing sustainability challenges.",
      link: "https://sus-tainability.netlify.app/",
      techStack: [
        { name: "React", src: react },
        { name: "Recoil", src: redux },
        { name: "PyTorch", src: pytorch },
        {
          name: "Python",
          src: "https://www.python.org/static/opengraph-icon-200x200.png",
        },
        { name: "Flask", src: flask },
        { name: "Ionic", src: ionic },
        { name: "TailwindCSS", src: tailwindcss },
      ],
      img_src: ecoquest,
      links: [
        {
          name: "Github",
          src: github,
          link: "https://github.com/sus-tainability",
        },
        {
          name: "Figma",
          src: figma,
          link: "https://www.figma.com/file/RElz1keXSeEx1dNBfV8eJv/SUS-App?node-id=1-4&t=ok8L4wSiVUBHcHc6-0",
        },
        {
          name: "Demo",
          src: youtube,
          link: "https://www.youtube.com/watch?v=a2bNogfSkVE",
        },
        {
          name: "Visit",
          src: visit,
          link: "https://sus-tainability.netlify.app/",
        },
      ],
    },
    {
      name: "Recyclops",
      description:
        "A Progressive Web App that aims to simplify recycling for Singaporeans. Empowered by machine learning, the user can scan any household object. Recyclops will inform them if it is safe to recycle, how to recycle it and whether any special instructions are needed. It also serves as an information dashboard of articles so users can access them easily to learn more.",
      link: "https://recyclops.netlify.app/home",
      techStack: [
        { name: "React", src: react },
        { name: "Redux", src: redux },
        { name: "Python", src: python },
        { name: "PyTorch", src: pytorch },
        { name: "Flask", src: flask },
        { name: "Ionic", src: ionic },
        { name: "TailwindCSS", src: tailwindcss },
      ],
      img_src: recyclops_image,
      links: [
        {
          name: "Github",
          src: github,
          link: "https://github.com/Recyclops-LifeHack-2022-Singapore",
        },
        {
          name: "Figma",
          src: figma,
          link: "https://www.figma.com/file/Von8ACBD5yA7R6gY5gBqIv/Recyclops?type=design&node-id=0-1",
        },
        {
          name: "Devpost",
          src: devpost,
          link: "https://devpost.com/software/recyclops-6lyh30",
        },
        {
          name: "Youtube",
          src: youtube,
          link: "https://www.youtube.com/watch?v=pyXI4r7pX9s",
        },
        {
          name: "Visit",
          src: visit,
          link: "https://recyclops.netlify.app/home",
        },
      ],
    },
    {
      name: "Get It Done",
      description:
        "A web application that helps you manage your tasks and projects seamlessly with a simple and intuitive interface. It has multiple views as well to view your upcoming tasks so that you can plan your day effectively and efficiently.",
      link: "https://bit.ly/GETITDONECVWO",
      techStack: [
        { name: "React", src: react },
        { name: "Redux", src: redux },
        { name: "Ruby", src: ruby },
        { name: "Rails", src: rails },
        { name: "PostgreSQL", src: postgresql },
        { name: "Docker", src: docker },
        { name: "Material UI", src: materialui },
        { name: "AWS EBS", src: aws_ebs },
        { name: "AWS EC2", src: aws_ec2 },
        { name: "AWS S3", src: aws_s3 },
      ],
      img_src: getitdone,
      links: [
        {
          name: "Github",
          src: github,
          link: "https://github.com/Santosh3007/todo-app",
        },
        // {
        //   name: "Visit",
        //   src: visit,
        //   link: "https://bit.ly/GETITDONECVWO",
        // },
      ],
    },
  ];

  return (
    <>
      <div
        ref={ref}
        className="mx-auto max-w-7xl px-4 pt-7 sm:px-6 lg:px-8 lg:mt-20"
      >
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-start">
            <span className="bg-white pr-3 text-5xl font-bold text-gray-900">
              Projects
            </span>
          </div>
        </div>
        <div>
          {projects.map((project, index) => (
            <div key={index} className="mt-12">
              <p className="text-3xl font-bold text-gray-600 mt-3">
                {project.name}
              </p>
              <div className="flex pl-3 pt-4">
                <div className="flex flex-grow flex-col pr-5">
                  <div className="flex-grow justify-end align-bottom">
                    <p className="text-xl font-medium text-gray-500">
                      {" "}
                      {project.description}
                    </p>
                  </div>
                  <div>
                    <TechStack
                      icons={project.techStack}
                      header="Tech &amp; Design Stack"
                    />
                  </div>
                  <div>
                    <TechStack icons={project.links} header="Links" />
                  </div>
                </div>
                <div className="flex-grow w-100">
                  <img
                    src={project.img_src}
                    alt=""
                    className="w-100  float-right"
                  />
                </div>
              </div>
              {index !== projects.length - 1 && (
                <div className="relative my-4 mb-4">
                  <div
                    className="absolute inset-0 flex items-center justify-center"
                    aria-hidden="true"
                  >
                    <div className="w-[80%] border-t border-gray-300 my-4" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

export default Projects;
